import React, { useState, useEffect } from "react";
import useInput from "../../../../hooks/use-input";
import { useQuery } from "../../../../hooks";
import api from "../../../../services/api";
import Toggle from "../../../ToggleInputs";

import { Link, useNavigate } from "react-router-dom";

import SideBar from "../../SideBar";
import InputText from "./Inputs";
import VaccineCheckBox from "../../../CheckBox";

function EditMissionary(props) {
  const [pueblo1, setPueblo1] = useState(null);
  const [pueblo2, setPueblo2] = useState(null);
  const [towns, setTowns] = useState([]);
  const [mission, setMission] = useState(null);
  const [exists, setExists] = useState(false);
  const [loading, setLoading] = useState(true);

  //TOGGLES STATES
  const [hasVaccine, setHasVaccine] = useState(false);
  const [hasVehicle, setHasVehicle] = useState(false);
  const [hasFirstAid, setHasFirstAid] = useState(false);
  const [hasPrisionExp, setHasPrisionExp] = useState(false);
  const [hasFoodIssue, setHasFoodIssue] = useState(false);
  const [hasHealthIssue, setHasHealthIssue] = useState(false);
  const [wantsGuide, setWantsGuide] = useState(false);

  let query = useQuery();
  let navigate = useNavigate();

  //PUEBLO 1
  const {
    value: enteredTown1,
    setValue: setEnteredTown1,
    valueChangedHandler: town1ChangedHandler,
  } = useInput((value) => value.trim() !== "");

  //PUEBLO 2
  const {
    value: enteredTown2,
    setValue: setEnteredTown2,
    valueChangedHandler: town2ChangedHandler,
  } = useInput((value) => value.trim() !== "");

  //REMERA
  const {
    value: enteredRemera,
    setValue: setRemera,
    valueChangedHandler: remeraChangedHandler,
  } = useInput((value) => value.trim() !== "");

  //OTHER CONDITIONS
  const {
    value: enteredOtherConditions,
    setValue: setEnteredOtherConditions,
    valueChangedHandler: otherConditionsChangedHandler,
    inputBlurHandler: otherConditionsBlurHandler,
  } = useInput((value) => (value) => value.trim() !== "");

  const {
    value: enteredOtherHealthConditions,
    setValue: setHealth,
    hasError: otherHealthConditionsHasError,
    isValid: otherHealthConditionsIsValid,
    valueChangedHandler: otherHealthConditionsChangedHandler,
    inputBlurHandler: otherHealthConditionsBlurHandler,
  } = useInput((value) => (value) => value.trim() !== "");

  //AÑOS DE MISION
  const {
    value: enteredMisionYears,
    setValue: setMisionYears,
    valueChangedHandler: misionYearsChangedHandler,
    inputBlurHandler: misionYearsBlurHandler,
    resetForm: resetMisionYears,
  } = useInput((value) => typeof parseInt(value) === "number", "0");

  //NOMBRE DEL CONTACTO
  const {
    value: enteredContact,
    setValue: setContact,
    valueChangedHandler: contactChangedHandler,
    inputBlurHandler: contactBlurHandler,
    resetForm: resetContact,
  } = useInput((value) => value.trim() !== "");

  //NUM DEL CONTACTO
  const {
    value: enteredContactNum,
    setValue: setContactNum,
    valueChangedHandler: contactNumChangedHandler,
    inputBlurHandler: contactNumBlurHandler,
    onlyNum: onlyNum,
  } = useInput((value) => value.trim() !== "");

  useEffect(() => {
    api.missions.getOne("active").then(setMission);
    api.towns.getMulti().then(setTowns);
    setLoading(true);
    api.entries
      .getOne(query.get("entryId"))
      .then((data) => {
        setPueblo1(data.town1);
        setPueblo2(data.town2);
        setRemera(data.shirtSize);
        setHasVehicle(data.hasVehicle);
        setHasFirstAid(data.hasFirstAidExperience);
        setWantsGuide(data.wantsGuide);
        setHasPrisionExp(data.hasPrisonExperience);
        setHasFoodIssue(!!data.otherConditions);
        setHasHealthIssue(!!data.otherConditions);
        console.log(
          "1",
          enteredOtherHealthConditions,
          "2",
          enteredOtherConditions
        );
        const [otherConditions, healthConditions] =
          data.otherConditions.split("|");
        setEnteredOtherConditions(otherConditions || "");
        setHealth(healthConditions || "");
        setMisionYears(data.missionYears);
        setContact(data.emergencyContactName);
        setContactNum(data.emergencyContactPhone);
        setHasVaccine(data.isVaccinated ? true : false);
        setExists(true);
        setLoading(false);
      })
      .catch((e) => {
        setExists(false);
      });
  }, [query]);

  const submissionHandler = (e) => {
    e.preventDefault();

    const data = {
      mission: mission.id,
      missionary: query.get("missionaryId"),
      town1: pueblo1?.id > 0 ? pueblo1.id : null,
      town2: pueblo2?.id > 0 ? pueblo2.id : null,
      shirtSize: enteredRemera,
      hasVehicle: hasVehicle,
      hasFirstAidExperience: hasFirstAid,
      hasPrisonExperience: hasPrisionExp,
      wantsGuide,
      otherConditions: `${enteredOtherConditions} | ${enteredOtherHealthConditions}`,
      missionYears: enteredMisionYears,
      emergencyContactName: enteredContact,
      emergencyContactPhone: enteredContactNum,
      isVaccinated: hasVaccine,
    };

    if (exists) {
      api.entries.update(query.get("entryId"), data).then((instance) => {
        navigate(
          `/admin/meetings/registration/in-out?entry=${
            instance.id
          }&meetingId=${query.get("meetingId")}&ci=${query.get(
            "ci"
          )}&meetingTitle=${query.get("meetingTitle")}`
        );
      });
    } else {
      api.entries.create(data).then((instance) => {
        navigate(
          `/admin/meetings/registration/in-out?entry=${
            instance.id
          }&meetingId=${query.get("meetingId")}&ci=${query.get(
            "ci"
          )}&meetingTitle=${query.get("meetingTitle")}`
        );
      });
    }
  };

  return (
    <>
      <SideBar />
      <div className="md:pl-80 md:grid md:grid-cols-4">
        <div className="mt-5 md:-mt-12 md:col-span-3 e-m-width">
          <form action="#" method="POST" onSubmit={submissionHandler}>
            <h3 className="font-extrabold text-gray-700 text-3xl title-get-data">
              Editar datos de la Misión del Misionero
            </h3>
            {!loading && (
              <div className="shadow overflow-hidden sm:rounded-md phone-layout">
                <div className="py-5 bg-white p-6">
                  <div className="grid grid-cols-6 gap-6">
                    {/* Pueblo 1 */}
                    <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                      <label
                        htmlFor="pueblo1-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Opción de pueblo 1
                      </label>
                      <p>
                        <strong>
                          {pueblo1?.name ? pueblo1.name : "Ninguno"}
                        </strong>
                      </p>
                    </div>

                    {/* Pueblo 2 */}
                    <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                      <label
                        htmlFor="pueblo2-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Opción de pueblo 2
                      </label>
                      <p>
                        <strong>
                          {pueblo2?.name ? pueblo2.name : "Ninguno"}
                        </strong>
                      </p>
                    </div>
                  </div>

                  <br />
                  <hr />
                  <br />

                  {/* Tamaño de remera */}
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="remera-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tamaño de remera
                      </label>
                      <select
                        id="remera-input"
                        name="remera-input"
                        autoComplete="off"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredRemera}
                        onChange={remeraChangedHandler}
                      >
                        <option value={"XS"}>Extra Pequeño</option>
                        <option value="S">Pequeño</option>
                        <option value="M">Mediano</option>
                        <option value="L">Grande</option>
                        <option value="XL">Extra Grande</option>
                      </select>
                    </div>
                  </div>

                  <br />
                  <hr />
                  <br />

                  {/* Toggles */}
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Toggle
                        text="Puedo llevar camioneta para la misión"
                        value={hasVehicle}
                        onChange={setHasVehicle}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Toggle
                        text="Tengo conocimientos para dar primeros auxilios"
                        value={hasFirstAid}
                        onChange={setHasFirstAid}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Toggle
                        text="Tengo experiencia en penitenciarías"
                        value={hasPrisionExp}
                        onChange={setHasPrisionExp}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Toggle
                        text="Quiero comprar un manual misionero"
                        onChange={setWantsGuide}
                        value={wantsGuide}
                      />
                    </div>
                  </div>

                  <br />
                  <hr />
                  <br />

                  {/* Toggles problemas */}
                  <div className="grid grid-cols-2 gap-x-6 gap-y-2 f-issue-lout-ph">
                    <div className="col-span-6 sm:col-span-3">
                      <Toggle
                        text="Tengo alguna restricción alimentaria"
                        value={hasFoodIssue}
                        onChange={setHasFoodIssue}
                      />
                    </div>
                    <br />
                    {hasFoodIssue && (
                      <div className="col-span-6 sm:col-span-3">
                        <input
                          type="text"
                          placeholder="Intolerancia a la lactosa, celiaquía, dieta vegana/vegetariana, etc"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ph-lout-f-issue-input"
                          value={
                            enteredOtherConditions?.charAt(0).toUpperCase() +
                            enteredOtherConditions?.slice(1)
                          }
                          onChange={otherConditionsChangedHandler}
                        />
                      </div>
                    )}
                    <br />
                    <div className="col-span-6 sm:col-span-3">
                      <Toggle
                        text="Tengo algún inconveniente de salud / estoy tomando medicamentos"
                        value={hasHealthIssue}
                        onChange={setHasHealthIssue}
                      />
                    </div>
                    <br />
                    {hasHealthIssue && (
                      <div className="col-span-6 sm:col-span-3">
                        <input
                          type="text"
                          placeholder="Medicamentos por alergia o diabetes, asma, etc."
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ph-lout-f-issue-input"
                          value={
                            enteredOtherHealthConditions
                              ?.charAt(0)
                              .toUpperCase() +
                            enteredOtherHealthConditions?.slice(1)
                          }
                          onChange={otherHealthConditionsChangedHandler}
                        />
                      </div>
                    )}
                  </div>

                  <br />
                  <hr />
                  <br />

                  <div className="grid grid-cols-6 gap-6">
                    {/* Años de mision */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="años-mision-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Años de misión en las MUC u otras misiones
                      </label>
                      <input
                        type="number"
                        id="años-mision-input"
                        name="años-mision-input"
                        placeholder="0"
                        autoComplete="off"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredMisionYears}
                        onChange={misionYearsChangedHandler}
                      ></input>
                    </div>
                  </div>

                  <br />
                  <hr />
                  <br />

                  {/* Contacto de emergencia  */}
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="emergency-name-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre de contacto de emergencia
                      </label>
                      <input
                        type="text"
                        name="emergency-name-input"
                        id="emergency-name-input"
                        autoComplete="off"
                        placeholder="Nombre y Apellido"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={
                          enteredContact.charAt(0).toUpperCase() +
                          enteredContact.slice(1)
                        }
                        onChange={contactChangedHandler}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="emergency-tel-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Celular del contacto
                      </label>
                      <input
                        type="text"
                        name="emergency-tel-input"
                        id="emergency-tel-input"
                        autoComplete="off"
                        placeholder="Celular"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredContactNum}
                        onChange={(contactNumChangedHandler, onlyNum)}
                      />
                    </div>
                  </div>

                  <br />
                  <hr />
                  <br />

                  {/* VACCINATED */}
                  {/*<div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Toggle
                        text="El misionero se ha vacunado contra el COVID-19"
                        value={hasVaccine}
                        onChange={setHasVaccine}
                      />
                    </div>
                      </div>}*/}
                </div>
                <div className="px-4 py-3 sm:px-6 text-center">
                  <button
                    type="submit"
                    className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default EditMissionary;
