// import ToMisionData from "../routes/ToMisionData";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo-muc.svg";
import TrackInscription from "./TrackInscription";
import useInput from "../hooks/use-input";
import { useQuery } from "../hooks";
import { useEffect, useState } from "react";
import api from "../services/api";
import Loader from "react-spinners/BarLoader";
import Modal from "./Modal";
import { Link } from "react-router-dom";

export default function GetPersonalData() {
  let query = useQuery();

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const stringValidator = (value) => {
    const trimmed = value.trim();
    return trimmed.length >= 2;
  };

  //NOMBRES
  const {
    value: enteredFirstName,
    setValue: setFirstName,
    isValid: enteredFirstNameIsValid,
    hasError: firstNameHasError,
    valueChangedHandler: firstNameChangedHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(stringValidator); //check if is empty

  //APELLIDOS
  const {
    value: enteredLastName,
    setValue: setLastName,
    isValid: enteredLastNameIsValid,
    hasError: lastNameHasError,
    valueChangedHandler: lastNameChangedHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(stringValidator);

  //EMAIL
  const {
    value: enteredEmail,
    setValue: setEmail,
    isValid: enteredEmailIsValid,
    hasError: emailHasError,
    valueChangedHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((v) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ? true : false
  );

  //TELEFÓNO
  const {
    value: enteredPhone,
    setValue: setPhone,
    isValid: enteredPhoneIsValid,
    hasError: phoneHasError,
    valueChangedHandler: phoneChangedHandler,
    inputBlurHandler: phoneBlurHandler,
    onlyNum,
  } = useInput(stringValidator);

  //GENERO
  const {
    value: enteredGender,
    setValue: setGender,
    isValid: enteredGenderIsValid,
    hasError: genderHasError,
    valueChangedHandler: genderChangedHandler,
    inputBlurHandler: genderBlurHandler,
  } = useInput((v) => v === "m" || v === "f" || v === "o");

  function isValidBirthDate(v) {
    const now = new Date();
    const vDate = new Date(v);
    const min = 17 * 365.25 * 24 * 3600000;
    const max = 34 * 365.25 * 24 * 3600000;

    if (now - vDate < min || now - vDate > max) {
      return false;
    }
    return true;
  }

  //FECHA DE NACIMIENTO
  const {
    value: enteredBornDate,
    setValue: setBornDate,
    isValid: enteredBornDateIsValid,
    hasError: bornDateHasError,
    valueChangedHandler: bornDateChangedHandler,
    inputBlurHandler: bornDateBlurHandler,
  } = useInput(isValidBirthDate);

  //DEPARTAMENTO
  const {
    value: enteredDepartment,
    setValue: setDepartment,
    isValid: enteredDepartmentIsValid,
    hasError: departmentHasError,
    valueChangedHandler: departmentChangedHandler,
    inputBlurHandler: departmentBlurHandler,
  } = useInput((v) => parseInt(v) >= 0);

  //PAIS
  const {
    value: enteredCountry,
    setValue: setCountry,
    isValid: enteredCountryIsValid,
    hasError: countryHasError,
    valueChangedHandler: countryChangedHandler,
    inputBlurHandler: countryBlurHandler,
  } = useInput(stringValidator);

  //CIUDAD
  const {
    value: enteredCity,
    setValue: setCity,
    isValid: enteredCityIsValid,
    hasError: cityHasError,
    valueChangedHandler: cityChangedHandler,
    inputBlurHandler: cityBlurHandler,
  } = useInput(stringValidator);

  //UNIVERSIDAD
  const {
    value: enteredUniversity,
    setValue: setUniversity,
    isValid: enteredUniversityIsValid,
    hasError: universityHasError,
    valueChangedHandler: universityChangedHandler,
    inputBlurHandler: universityBlurHandler,
  } = useInput(stringValidator);

  //CARRERA
  const {
    value: enteredCareer,
    setValue: setCareer,
    isValid: enteredCareerIsValid,
    hasError: careerHasError,
    valueChangedHandler: careerChangedHandler,
    inputBlurHandler: careerBlurHandler,
  } = useInput(stringValidator);

  //LUGAR DE TRABAJO
  const {
    value: enteredJob,
    setValue: setJob,
    valueChangedHandler: jobChangedHandler,
  } = useInput(stringValidator);

  const [exists, setExists] = useState(false);
  const [birthdateErrorText, setBirthdateErrorText] = useState("");

  const birthdateHandler = (e) => {
    setBornDate(e.target.value);
  };

  //GETTING DATA FROM BACKEND
  useEffect(() => {
    api.missionaries
      .getOne(query.get("govId"))
      .then((data) => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.cellphone);
        setGender(data.gender);
        setBornDate(data.birthDate);
        setDepartment(data.department);
        setCountry(data.country);
        setCity(data.city);
        setUniversity(data.university); //no info
        setCareer(data.degree); //no info
        setJob(data.workplace); //no info
        setExists(true);
        setLoading(false);
        setShowModal(true);
      })
      .catch((e) => {
        setExists(false);
        setLoading(false);
      });
  }, [query]);

  const isFormValid = () => {
    return (
      enteredFirstNameIsValid &&
      enteredLastNameIsValid &&
      enteredEmailIsValid &&
      enteredPhoneIsValid &&
      enteredGenderIsValid &&
      enteredBornDateIsValid &&
      enteredGenderIsValid &&
      enteredDepartmentIsValid &&
      enteredCountryIsValid &&
      enteredCityIsValid &&
      enteredUniversityIsValid &&
      enteredCareerIsValid
    );
  };

  const submissionHandler = (e) => {
    e.preventDefault();

    const data = {
      issuedId: query.get("govId"),
      firstName: enteredFirstName,
      lastName: enteredLastName,
      email: enteredEmail,
      cellphone: enteredPhone,
      gender: enteredGender,
      birthDate: enteredBornDate,
      department: enteredDepartment,
      country: enteredCountry,
      city: enteredCity,
      university: enteredUniversity,
      degree: enteredCareer,
      workplace: enteredJob,
    };

    if (exists) {
      api.missionaries.update(query.get("govId"), data).then((instance) => {
        navigate(`/mision-data?missionaryId=${instance.id}`);
      });
    } else {
      api.missionaries.create(data).then((instance) => {
        navigate(`/mision-data?missionaryId=${instance.id}`);
      });
    }
  };

  let navigate = useNavigate();
  return (
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 h-screen">
        <div className="md:col-span-1 bg-white pt-5 pl-10 pb-10">
          <div className="px-4 sm:px-0">
            <Link to="/">
              <img src={logo} alt="logo" className="logo-in-pd logo-in-phone" />
            </Link>
            <TrackInscription />
          </div>
        </div>
        <div className="mt-5 md:mt-8 md:col-span-3 p-3">
          <form action="#" method="POST" onSubmit={submissionHandler}>
            <h3 className="font-extrabold text-gray-700 text-3xl title-get-data">
              Datos Personales
            </h3>
            {loading && <Loader />}
            {!loading && (
              <>
                <div className="shadow overflow-hidden sm:rounded-md md:mx-10">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      {/* Nombres */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nombres <span className="require-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="first-name"
                          autoComplete="off"
                          placeholder="Tus nombres"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={
                            enteredFirstName.charAt(0).toUpperCase() +
                            enteredFirstName.slice(1)
                          }
                          onChange={firstNameChangedHandler}
                          onBlur={firstNameBlurHandler}
                        />
                        {firstNameHasError && (
                          <p className="error-text">Debe ingresar su nombre.</p>
                        )}
                      </div>

                      {/* Apellido */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Apellidos <span className="require-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="secondName"
                          id="last-name"
                          autoComplete="off"
                          placeholder="Tus apellidos"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={
                            enteredLastName.charAt(0).toUpperCase() +
                            enteredLastName.slice(1)
                          }
                          onChange={lastNameChangedHandler}
                          onBlur={lastNameBlurHandler}
                        />
                        {lastNameHasError && (
                          <p className="error-text">
                            Debe ingresar su apellido.
                          </p>
                        )}
                      </div>

                      {/* Email */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Correo <span className="require-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email-input"
                          autoComplete="off"
                          placeholder="nombre@gmail.com"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={enteredEmail}
                          onChange={emailChangedHandler}
                          onBlur={emailBlurHandler}
                        />
                        {emailHasError && (
                          <p className="error-text">
                            Debe ingresar un email válido.
                          </p>
                        )}
                      </div>

                      {/* Telefono */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="tel-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Celular <span className="require-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="phone"
                          id="tel-input"
                          autoComplete="off"
                          placeholder="0981234567"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={enteredPhone}
                          onChange={(phoneChangedHandler, onlyNum)}
                          onBlur={phoneBlurHandler}
                        />
                        {phoneHasError && (
                          <p className="error-text">
                            Debe ingresar su teléfono.
                          </p>
                        )}
                      </div>

                      {/* Genero */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="gender-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Género <span className="require-field">*</span>
                        </label>
                        <select
                          name="gender"
                          id="gender-input"
                          autoComplete="Gender-type"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={enteredGender}
                          onChange={genderChangedHandler}
                          onBlur={genderBlurHandler}
                        >
                          <option>--</option>
                          {/* <option value="o">Prefiero no especificar</option> */}
                          <option value="m">Hombre</option>
                          <option value="f">Mujer</option>
                        </select>
                        {genderHasError && (
                          <p className="error-text">Debe ingresar su género.</p>
                        )}
                      </div>

                      {/* Fecha de nacimiento */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="born-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Fecha de nacimiento{" "}
                          <span className="require-field">*</span>
                        </label>
                        <input
                          type="date"
                          name="bornDate"
                          id="born-input"
                          autoComplete="off"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={enteredBornDate}
                          onChange={birthdateHandler}
                          onBlur={bornDateBlurHandler}
                          max="2007-12-31"
                          min="1989-01-01"
                        />
                        {bornDateHasError && (
                          <p className="error-text">
                            Debe tener entre 17 y 33 años
                          </p>
                        )}
                      </div>

                      {/* Pais */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="country-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          País <span className="require-field">*</span>
                        </label>
                        <select
                          name="country"
                          id="country-input"
                          autoComplete="country-name"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={enteredCountry}
                          onChange={countryChangedHandler}
                          onBlur={countryBlurHandler}
                        >
                          <option>--</option>
                          <option value="PY">Paraguay</option>
                          <option value="AR">Argentina</option>
                          <option value="BR">Brasil</option>
                          <option value="UY">Uruguay</option>
                          <option value="CL">Chile</option>
                          <option value="OT">Otros</option>
                        </select>
                        {countryHasError && (
                          <p className="error-text">Debe ingresar su País.</p>
                        )}
                      </div>

                      {/* Ciudad */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="city-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Ciudad <span className="require-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city-input"
                          autoComplete="off"
                          placeholder="Tu ciudad"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={
                            enteredCity.charAt(0).toUpperCase() +
                            enteredCity.slice(1)
                          }
                          onChange={cityChangedHandler}
                          onBlur={cityBlurHandler}
                        />
                        {cityHasError && (
                          <p className="error-text">Debe ingresar su ciudad.</p>
                        )}
                      </div>

                      {/* Departamento */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="department-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Departamento <span className="require-field">*</span>
                        </label>
                        <select
                          name="department"
                          id="department-input"
                          autoComplete="department-name"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={enteredDepartment}
                          onChange={departmentChangedHandler}
                          onBlur={departmentBlurHandler}
                        >
                          <option>--</option>
                          <option value="13">Amambay</option>
                          <option value="17">Alto Paraguay</option>
                          <option value="10">Alto Parana</option>
                          <option value="0">Asuncion</option>
                          <option value="16">Boqueron</option>
                          <option value="5">Caaguazu</option>
                          <option value="6">Caazapa</option>
                          <option value="14">Canindeyu</option>
                          <option value="11">Central</option>
                          <option value="1">Concepción</option>
                          <option value="3">Coordillera</option>
                          <option value="4">Guaira</option>
                          <option value="7">Itapua</option>
                          <option value="8">Misiones</option>
                          <option value="12">Neembucu</option>
                          <option value="9">Paraguari</option>
                          <option value="15">Presidente Hayes</option>
                          <option value="2">San Pedro</option>
                          <option value="99">Exterior</option>
                        </select>
                        {departmentHasError && (
                          <p className="error-text">
                            Debe ingresar su Departamento.
                          </p>
                        )}
                      </div>

                      {/* Universidad */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="university-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Universidad <span className="require-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="university"
                          id="university-input"
                          autoComplete="off"
                          placeholder="Tu universidad"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={
                            enteredUniversity.charAt(0).toUpperCase() +
                            enteredUniversity.slice(1)
                          }
                          onChange={universityChangedHandler}
                          onBlur={universityBlurHandler}
                        />
                        {universityHasError && (
                          <p className="error-text">
                            Debe ingresar su universidad.
                          </p>
                        )}
                      </div>

                      {/* Carrera */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="career-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Carrera <span className="require-field">*</span>
                        </label>
                        <input
                          type="text"
                          name="career"
                          id="career-input"
                          autoComplete="off"
                          placeholder="Tu carrera"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={
                            enteredCareer.charAt(0).toUpperCase() +
                            enteredCareer.slice(1)
                          }
                          onChange={careerChangedHandler}
                          onBlur={careerBlurHandler}
                        />
                        {careerHasError && (
                          <p className="error-text">
                            Debe ingresar su carrera.
                          </p>
                        )}
                      </div>

                      {/* Trabajo */}
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="job-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Lugar de trabajo (Opcional)
                        </label>
                        <input
                          type="text"
                          name="job"
                          id="job-input"
                          autoComplete="off"
                          placeholder="Lugar de trabajo"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={
                            enteredJob.charAt(0).toUpperCase() +
                            enteredJob.slice(1)
                          }
                          onChange={jobChangedHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:px-6 text-center">
                    <button
                      type="submit"
                      className="w-full place-content-center font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                      disabled={!isFormValid()}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
                <Modal
                  name={`${enteredFirstName} ${enteredLastName}`}
                  show={showModal}
                  onClose={() => setShowModal(false)}
                />
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
