import React, { useState, useEffect } from "react";
import useInput from "../../../../hooks/use-input";
import { useQuery } from "../../../../hooks";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../../SideBar";
import api from "../../../../services/api";

function EditMissionary() {
  let query = useQuery();
  let navigate = useNavigate();

  const stringValidator = (value) => {
    const trimmed = value.trim();
    return trimmed.length >= 2;
  };
  const [exists, setExists] = useState(false);

  //NOMBRES
  const {
    value: enteredFirstName,
    setValue: setFirstName,
    valueChangedHandler: firstNameChangedHandler,
  } = useInput(stringValidator); //check if is empty

  //APELLIDOS
  const {
    value: enteredLastName,
    setValue: setLastName,
    valueChangedHandler: lastNameChangedHandler,
  } = useInput(stringValidator);

  //EMAIL
  const {
    value: enteredEmail,
    setValue: setEmail,
    valueChangedHandler: emailChangedHandler,
  } = useInput((v) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ? true : false
  );

  //TELEFÓNO
  const {
    value: enteredPhone,
    setValue: setPhone,
    valueChangedHandler: phoneChangedHandler,
    onlyNum,
  } = useInput(stringValidator);

  //GENERO
  const {
    value: enteredGender,
    setValue: setGender,
    valueChangedHandler: genderChangedHandler,
  } = useInput((v) => v === "m" || v === "f" || v === "o");
  //FECHA DE NACIMIENTO
  const {
    value: enteredBornDate,
    setValue: setBornDate,
    valueChangedHandler: bornDateChangedHandler,
  } = useInput((v) => v);

  //PAIS
  const {
    value: enteredCountry,
    setValue: setCountry,
    valueChangedHandler: countryChangedHandler,
  } = useInput(stringValidator);

  //CIUDAD
  const {
    value: enteredCity,
    setValue: setCity,
    valueChangedHandler: cityChangedHandler,
  } = useInput(stringValidator);

  //DEPARTAMENTO
  const {
    value: enteredDepartment,
    setValue: setDepartment,
    hasError: departmentHasError,
    valueChangedHandler: departmentChangedHandler,
    inputBlurHandler: departmentBlurHandler,
  } = useInput((v) => parseInt(v) >= 0);

  const birthdateHandler = (e) => {
    setBornDate(e.target.value);
  };

  useEffect(() => {
    api.missionaries
      .getOne(query.get("ci"))
      .then((data) => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.cellphone);
        setGender(data.gender);
        setBornDate(data.birthDate);
        setDepartment(data.department);
        setCountry(data.country);
        setCity(data.city);
        setExists(true);
      })
      .catch((e) => {
        setExists(false);
      });
  }, [query]);

  const submissionHandler = (e) => {
    e.preventDefault();

    const data = {
      issuedId: query.get("ci"),
      firstName: enteredFirstName,
      lastName: enteredLastName,
      email: enteredEmail,
      cellphone: enteredPhone,
      gender: enteredGender,
      birthDate: enteredBornDate,
      country: enteredCountry,
      city: enteredCity,
      department: enteredDepartment,
    };

    api.missionaries.update(query.get("ci"), data).then((instance) => {
      query.append("missionaryId", instance.id);
      navigate(`/admin/meetings/registration/entry-data?${query.toString()}`);
    });
  };
  return (
    <>
      <SideBar />
      <div>
        <div className="md:pl-72 md:grid md:grid-cols-4 md:gap-6 ">
          <div className="mt-5 md:mt-8 md:col-span-3 p-3">
            <form action="#" method="POST" onSubmit={submissionHandler}>
              <h3 className="font-extrabold text-gray-700 text-3xl title-get-data">
                Editar Datos del Misionero
              </h3>
              <div className="shadow overflow-hidden sm:rounded-md md:mx-10">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    {/* Nombres */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombres
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="first-name"
                        autoComplete="off"
                        placeholder="Misionero/a"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={
                          enteredFirstName.charAt(0).toUpperCase() +
                          enteredFirstName.slice(1)
                        }
                        onChange={firstNameChangedHandler}
                      />
                    </div>

                    {/* Apellido */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Apellidos
                      </label>
                      <input
                        type="text"
                        name="secondName"
                        id="last-name"
                        autoComplete="off"
                        placeholder="Tu apellido"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={
                          enteredLastName.charAt(0).toUpperCase() +
                          enteredLastName.slice(1)
                        }
                        onChange={lastNameChangedHandler}
                      />
                    </div>

                    {/* Email */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email-input"
                        autoComplete="off"
                        placeholder="nombre@gmail.com"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredEmail}
                        onChange={emailChangedHandler}
                      />
                    </div>

                    {/* Telefono */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="tel-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Teléfono
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="tel-input"
                        autoComplete="off"
                        placeholder="0981234567"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredPhone}
                        onChange={phoneChangedHandler}
                      />
                    </div>

                    {/* Genero */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="gender-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Género
                      </label>
                      <select
                        name="gender"
                        id="gender-input"
                        autoComplete="Gender-type"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredGender}
                        onChange={genderChangedHandler}
                      >
                        <option>--</option>
                        {/* <option value="o">Prefiero no especificar</option> */}
                        <option value="m">Hombre</option>
                        <option value="f">Mujer</option>
                      </select>
                    </div>

                    {/* Fecha de nacimiento */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="born-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fecha de nacimiento{" "}
                      </label>
                      <input
                        type="date"
                        name="bornDate"
                        id="born-input"
                        autoComplete="off"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredBornDate}
                        onChange={bornDateChangedHandler}
                        max="2006-12-31"
                        min="1988-01-01"
                      />
                    </div>

                    {/* Pais */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="country-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        País
                      </label>
                      <select
                        name="country"
                        id="country-input"
                        autoComplete="country-name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredCountry}
                        onChange={countryChangedHandler}
                      >
                        <option>--</option>
                        <option value="PY">Paraguay</option>
                        <option value="AR">Argentina</option>
                        <option value="BR">Brasil</option>
                        <option value="UY">Uruguay</option>
                        <option value="CL">Chile</option>
                        <option value="OT">Otros</option>
                      </select>
                    </div>

                    {/* Ciudad */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="city-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Ciudad
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city-input"
                        autoComplete="off"
                        placeholder="Asunción"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={
                          enteredCity.charAt(0).toUpperCase() +
                          enteredCity.slice(1)
                        }
                        onChange={cityChangedHandler}
                      />
                    </div>

                    {/* Departamento */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="department-input"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Departamento
                      </label>
                      <select
                        name="department"
                        id="department-input"
                        autoComplete="department-name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={enteredDepartment}
                        onChange={departmentChangedHandler}
                        onBlur={departmentBlurHandler}
                      >
                        <option>--</option>
                        <option value="13">Amambay</option>
                        <option value="17">Alto Paraguay</option>
                        <option value="10">Alto Parana</option>
                        <option value="0">Asuncion</option>
                        <option value="16">Boqueron</option>
                        <option value="5">Caaguazu</option>
                        <option value="6">Caazapa</option>
                        <option value="14">Canindeyu</option>
                        <option value="11">Central</option>
                        <option value="1">Concepción</option>
                        <option value="3">Coordillera</option>
                        <option value="4">Guaira</option>
                        <option value="7">Itapua</option>
                        <option value="8">Misiones</option>
                        <option value="12">Neembucu</option>
                        <option value="9">Paraguari</option>
                        <option value="15">Presidente Hayes</option>
                        <option value="2">San Pedro</option>
                        <option value="99">Exterior</option>
                      </select>
                      {departmentHasError && (
                        <p className="error-text">
                          Debe ingresar su Departamento.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6 text-center">
                  <button
                    type="submit"
                    className="w-full place-content-center font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditMissionary;
