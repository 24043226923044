import React from 'react';

const SearchInput = ({ searchValue, setSearchValue, handleInputChange }) => {
  return (
    <form className="w-1/3 mr-2">
      <label
        htmlFor="simple-search"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Buscar:
      </label>
      <div className="relative w-11/12 mt-2">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          id="simple-search"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Nombre o número de cédula"
          //required
          value={searchValue}
          onChange={handleInputChange}
        />
        {searchValue.length > 0 && (
          <button
            onClick={() => setSearchValue('')}
            className="outline-none focus:outline-none absolute inset-y-0 right-0 flex items-center p-3 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        )}
      </div>

      {/*<button
        type="submit"
        className="btn-sig inline-flex items-center py-2.5 px-3 ml-2 text-sm font-medium text-white rounded-lg"
      >
        <span className="text-white">Buscar</span>
  </button>*/}
    </form>
  );
};

export default SearchInput;
