import IconUserGroup, {
  IconLocation,
  IconCash,
  IconCobros,
} from "../components/dashboard/iconsDashboard";
// import IconUserGroup from "../components/dashboard/iconsDashboard";

//SIDEBAR DATA NAVIGATION
const navigation = [
  // {
  //   name: "Misioneros",
  //   href: "/admin/missionaries",
  //   icon: IconUserGroup,
  //   current: false,
  // },
  {
    name: "Registro de Asistencia",
    href: "/admin/meetings",
    icon: IconLocation,
    current: false,
  },
  {
    name: "Pagos",
    href: "/admin/payments",
    icon: IconCash,
    current: false,
  },
  {
    name: "Cobros",
    href: "/admin/cobros",
    icon: IconCobros,
    current: false,
  },
  {
    name: "Misioneros",
    href: "/admin/missionaries-list",
    icon: IconUserGroup,
    current: false,
  },
];

//USER DATA NAVIGATION
const userNavigation = [
  // { name: "Your Profile", href: "#" },
  // { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

export default navigation;
export { userNavigation };
