import axios from 'axios';
import { createContext, useContext, useState } from 'react';
import api from '../services/api';

/**
 * Decodes a JWT
 * @param {String} token Encoded JWT
 * @returns Object with decoded JWT data
 */
function parseJwt(token) {
  if (!token) {
    return null;
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  let accessToken = localStorage.getItem('accessToken');
  const [userData, setUserData] = useState(
    accessToken ? JSON.parse(localStorage.getItem('userData')) : null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(!!accessToken);
  let interceptorId = null;

  const requestInterceptor = (config) => {
    /* If JWT is not expired, add Authorization header. Else, redirect to login */
    if (accessToken) {
      config.headers['Authorization'] = `Token ${accessToken}`;
      return config;
    } else {
      setIsAuthenticated(false);
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
    }
  };

  const responseInterceptor = (response) => {
    if (response.status === 401) {
      signOut();
    }
    return response;
  };

  /**
   * Attempts to log a user into the system
   * @param {String} username username
   * @param {*} password user password
   * @returns Object with `status: "success"` if user was logged in or
   * `status: "error"` otherwise.
   * Additonally, a `status: "error"` can contain a `detail` key with extra info.
   */
  const signIn = async (username, password) => {
    try {
      const response = await api.users.getToken(username, password);
      accessToken = response.token;
      localStorage.setItem('accessToken', accessToken);

      interceptorId = axios.interceptors.request.use(requestInterceptor);
      axios.interceptors.response.use(responseInterceptor);
      const _userData = response.user;
      localStorage.setItem('userData', JSON.stringify(_userData));
      setIsAuthenticated(true);
      setUserData(_userData);
      return Promise.resolve({ status: 'success' });
    } catch (e) {
      return Promise.reject({ status: 'error', detail: 'Invalid credentials' });
    }
  };

  const signOut = () => {
    interceptorId = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');
    window.location.reload();
  };

  // Check that interceptor is added when authentication status changes
  if (isAuthenticated && !interceptorId) {
    interceptorId = axios.interceptors.request.use(requestInterceptor);
  }

  const [userId, setUserId] = useState(null);
  const context = {
    isAuthenticated,
    signIn,
    signOut,
    userId,
    setUserId,
    userData,
  };
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

const AuthConsumer = ({ children }) => (
  <AuthContext.Consumer>{children}</AuthContext.Consumer>
);

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthConsumer, useAuth };
export default AuthContext;
