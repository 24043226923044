function parseCI(value) {
  const regex = /[0-9-]+/gm;
  let m;
  let parsedValue = "";

  while ((m = regex.exec(value)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      parsedValue += match;
    });
  }
  return parsedValue;
}

function formatDate(dateStr) {
  return new Date(dateStr).toLocaleDateString("es-PY", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}

function thousandSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export { parseCI, formatDate, thousandSeparator };
