import React from "react";
import InsertCedula from "./pages/InsertCedula";
import PersonalData from "./pages/PersonalData";
import MisionData from "./pages/MisionData";
import Confirmation from "./pages/Confirmation";
import VerifyInscription from "./pages/VerifyInscription";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MyInscription from "./pages/MyInscription";
import Login from "./pages/dashboard/Login";
import Missionaries from "./pages/dashboard/Missionaries";
import Encounters from "./pages/dashboard/Encounters";
import Payment from "./pages/dashboard/Payment";
import EncountersRegistPart2 from "./components/dashboard/encounters/EncountersRegistPart2";
import EncountersRegistPart3 from "./components/dashboard/encounters/EncountersRegistPart3";
import PaymentRegist from "./components/dashboard/payment/PaymentRegist";
// import EditMissionary from "./components/dashboard/edit/EditMissionary";
import { useAuth } from "./context/authContext";
import Cobros from "./components/dashboard/cobros/Cobros";
import EditMissionary from "./components/dashboard/encounters/editInfo/EditMissionary";
import EditMission from "./components/dashboard/encounters/editInfo/EditMission";
import MissionariesList from "./components/dashboard/missionaries/MissionariesList";
import Footer from "./components/Footer";

function RequireAuth({ children, redirectTo }) {
  let auth = useAuth();
  return auth.isAuthenticated ? <>{children}</> : <Navigate to={redirectTo} />;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/inscription" />} />
        <Route path="/inscription" element={<InsertCedula />} />
        <Route path="/personal-data" element={<PersonalData />} />
        <Route path="/mision-data" element={<MisionData />} />
        <Route path="/confirm-inscription" element={<Confirmation />} />
        <Route path="/verify-entry" element={<VerifyInscription />} />
        <Route path="/check-entry" element={<MyInscription />} />

        {/* Dashboard  */}
        <Route path="/login" element={<Login />} />
        <Route
          path="/admin/missionaries"
          exact
          element={
            <RequireAuth redirectTo="/login">
              <Missionaries />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/admin/missionaries/:id"
          element={
            <RequireAuth redirectTo="/login">
              <EditMissionary />
            </RequireAuth>
          }
        /> */}

        {/**/}
        {/*ENCOUNTERS*/}
        {/**/}

        <Route
          path="/admin/meetings"
          exact
          element={
            <RequireAuth redirectTo="/login">
              <Encounters />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/meetings/registration"
          element={
            <RequireAuth redirectTo="/login">
              <EncountersRegistPart2 />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/meetings/registration/missionary-data"
          element={
            <RequireAuth redirectTo="/login">
              <EditMissionary />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/meetings/registration/entry-data"
          element={
            <RequireAuth redirectTo="/login">
              <EditMission />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/meetings/registration/in-out"
          element={
            <RequireAuth redirectTo="/login">
              <EncountersRegistPart3 />
            </RequireAuth>
          }
        />

        {/**/}
        {/*PAYMENT*/}
        {/**/}
        <Route
          path="/admin/payments"
          exact
          element={
            <RequireAuth redirectTo="/login">
              <Payment />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/payments/register"
          element={
            <RequireAuth redirectTo="/login">
              <PaymentRegist />
            </RequireAuth>
          }
        />

        {/**/}
        {/*COBROS*/}
        {/**/}
        <Route
          path="/admin/cobros"
          exact
          element={
            <RequireAuth redirectTo="/login">
              <Cobros />
            </RequireAuth>
          }
        />

        {/**/}
        {/*Misioneros*/}
        {/**/}
        <Route
          path="/admin/missionaries-list"
          exact
          element={
            <RequireAuth redirectTo="/login">
              <MissionariesList />
            </RequireAuth>
          }
        />

        <Route
          path="/admin"
          element={
            <RequireAuth redirectTo="/login">
              <Navigate to="/admin/meetings" />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
