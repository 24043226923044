import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '../../../hooks';
import SideBar from '../../dashboard/SideBar';

import api, { errorCodes } from '../../../services/api';
import Banner from '../../../components/Banner';

import { parseCI } from '../../../../src/utils';

function EncountersRegistPart2() {
  let query = useQuery();
  let navigate = useNavigate();

  const [cedula, setCedula] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showBanner, setShowBanner] = useState(
    query.get('status') === 'success'
  );

  const handleCedulaChange = (e) => {
    setCedula(parseCI(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    api.meetings
      .check(query.get('meetingId'), cedula)
      .then((res) => {
        query.append('ci', cedula);
        query.append('entryId', res.entry.id);
        if (res.hasCheckIn) {
          navigate(
            `/admin/meetings/registration/in-out?entry=${
              res.entry.id
            }&meetingId=${query.get(
              'meetingId'
            )}&ci=${cedula}&meetingTitle=${query.get('meetingTitle')}`
          );
        } else {
          navigate(
            `/admin/meetings/registration/missionary-data?${query.toString()}`
          );
        }
      })
      .catch((e) => {
        if (e === errorCodes.notFound) {
          setErrorMessage(
            'No se encontró un misionero inscripto con ese documento.'
          );
        } else {
          setErrorMessage(
            'Error inesperado. Por favor intente de nuevo en unos minutos.'
          );
        }
      });
  };

  return (
    <>
      <div>
        <SideBar />
        <div className="md:pl-64 sm:px-6 md:px-8">
          <div className="md:grid place-items-center md:mt-24 sm:rounded-lg body-inscription">
            <div className="py-5 sm:p-6 phone-lout-gc">
              <h1 className="font-extrabold text-gray-700 title-cedula">
                Registro de Asistencia
                <br />
                Encuentro Previo: {query.get('meetingTitle')}
              </h1>
              <div className="py-10 px-10 bg-white card-cedula ">
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="max-w-xl">
                      <label htmlFor="select-date" className="text-black-500">
                        <h1>
                          <strong>Número de CI</strong>
                        </h1>
                      </label>
                      <input
                        type="text"
                        name="cedula"
                        id="cedula"
                        className="mt-2 px-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full block sm:text-sm border-gray-300 rounded-md input-cedula "
                        placeholder="1234567"
                        onChange={handleCedulaChange}
                        value={cedula}
                      />
                    </div>
                    {errorMessage && (
                      <small class="text-red-700">{errorMessage}</small>
                    )}
                    <br />
                    {/* momentaneo */}
                    {/* <Link to="/admin/meetings/registration/:id"> */}
                    <button
                      type="submit"
                      className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                    >
                      Siguiente
                    </button>
                    {/* </Link> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showBanner && (
        <Banner
          message="Registro guardado con éxito."
          onClose={() => setShowBanner(false)}
        />
      )}
    </>
  );
}

export default EncountersRegistPart2;

// const handleSubmit = (e) => {
//   e.preventDefault();
//   api.meetings
//     .check(query.get("meetingId"), cedula)
//     .then((res) => {
//       navigate(
//         `/admin/meetings/registration/:id?${query.toString()}&ci=${cedula}`
//       );
//     })
//     .catch((e) => {
//       if (e === errorCodes.notFound) {
//         setErrorMessage(
//           "No se encontró un misionero inscripto con ese documento."
//         );
//       } else {
//         setErrorMessage(
//           "Error inesperado. Por favor intente de nuevo en unos minutos."
//         );
//       }
//     });
// };

//  const handleSubmit = (e) => {
//    e.preventDefault();
//    api.meetings
//      .check(cedula)
//      .then((res) => {
//        navigate(`/admin/meetings/registration/:id?ci=${cedula}`);
//      })
//      .catch((e) => {
//        if (e === errorCodes.notFound) {
//          setErrorMessage(
//            "No se encontró un misionero inscripto con ese documento."
//          );
//        } else {
//          setErrorMessage(
//            "Error inesperado. Por favor intente de nuevo en unos minutos."
//          );
//        }
//      });
//  };
