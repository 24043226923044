import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { peoplePayment } from './people';

import { parseCI } from "../../../utils";
import api from "../../../services/api";
import { useQuery } from "../../../hooks";
import Banner from "../../Banner";
import SideBar from "../SideBar";
import navigation from "../../../constants/sideCategories";

function PaymentDash() {
  const [cedula, setCedula] = useState("");
  const [errors, setErrors] = useState([]);
  let navigate = useNavigate();
  let query = useQuery();
  const [showBanner, setShowBanner] = useState(
    query.get("status") === "success"
  );

  const handleCedulaChange = (e) => {
    setCedula(parseCI(e.target.value));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    api.payments
      .create({
        type: "check",
        missionary: cedula,
      })
      .then((res) => {
        navigate(`/admin/payments/register?ci=${cedula}`);
      })
      .catch((e) => {
        if (e.response.status === 400 && e.response.data.nonFieldErrors) {
          setErrors(e.response.data.nonFieldErrors);
        } else if (e.response.satus === 404) {
          setErrors(["No se encontró el misionero."]);
        }
      });
  };

  return (
    <>
      <SideBar
        name={"Pagos"}
        href={"/admin/payments"}
        current={[
          (navigation[0].current = false),
          (navigation[1].current = true),
          (navigation[2].current = false),
          (navigation[3].current = false),
        ]}
      />
      <div className="md:pl-64 sm:px-6 md:px-8">
        <div className="md:grid place-items-center md:mt-32 sm:rounded-lg body-inscription">
          <div className="px-4 py-5 sm:p-6 phone-lout-gc">
            <h1 className="font-extrabold text-gray-700 title-cedula">
              Registro de pagos
              {/* {mission?.name} */}
            </h1>
            <div className="py-10 px-10 bg-white card-cedula">
              <div>
                <form onSubmit={onSubmit}>
                  <div className="max-w-xl">
                    <label htmlFor="cedula" className="text-black-500">
                      <h1>
                        <strong>Numero de cédula</strong>
                      </h1>
                    </label>
                    <input
                      name="cedula"
                      id="cedula"
                      className="mt-2 px-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full block sm:text-sm border-gray-300 rounded-md input-cedula "
                      placeholder="1234567"
                      value={cedula}
                      onChange={handleCedulaChange}
                    />
                  </div>
                  {errors.map((e) => (
                    <small className="text-red-700">{e}</small>
                  ))}
                  <br />
                  {/* <Link to="/admin/payments/register"> */}
                  <button
                    type="submit"
                    className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                  >
                    Siguiente
                  </button>
                  {/* </Link> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBanner && (
        <Banner
          message="Pago registrado con éxito."
          onClose={() => setShowBanner(false)}
        />
      )}
    </>
  );
}

export default PaymentDash;
