import React, { useEffect } from 'react';
import { useState, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import api from '../../../services/api';
import useInput from '../../../hooks/use-input';

function ModalTransfer({
  modalTransfer,
  setModalTransfer,
  missionary,
  setRefresh,
}) {
  const cancelButtonRef = useRef(null);
  const [towns, setTowns] = useState([]);
  const [selectedTown, setSelectedTown] = useState('');

  const transfer = () => {
    const data = {
      entry_id: missionary.id,
      town_id: selectedTown,
    };
    api.transfer.create(data).then((res) => console.log('works!', res));
    setModalTransfer(false);
    setRefresh(true);
  };

  useEffect(() => {
    async function fetchData() {
      const _mission = await api.missions.getOne('active');
      api.towns
        .getMulti(null, null, 100, 0, { missions: _mission.id })
        .then((data) => {
          setTowns(data);
          setSelectedTown(data[0].id);
        });
    }
    fetchData();
  }, []);

  return (
    <Transition.Root show={modalTransfer} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setModalTransfer}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 mb-4"
                      >
                        Transferir
                      </Dialog.Title>
                      <p className="mb-2">
                        Misionero:{' '}
                        {missionary.missionary.firstName +
                          ' ' +
                          missionary.missionary.lastName}
                      </p>
                      <p>Pueblo de destino</p>
                      <select
                        id="pueblo-input"
                        name="pueblo-input"
                        autoComplete="off"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={selectedTown}
                        onChange={(e) => setSelectedTown(e.target.value)}
                      >
                        {towns.map((town) => (
                          <option value={town.id} key={town.id}>
                            {town.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center items-center rounded-md btn-sig px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    onClick={transfer}
                  >
                    Aceptar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setModalTransfer(false)}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ModalTransfer;
