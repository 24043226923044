import "./getLogin.css";
import logo from "../../images/logo-muc.svg";
import EyeIcon, { EyeIconOff } from "./iconLogin";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useInput from "../../hooks/use-input";
import { useAuth } from "../../context/authContext";
import Footer from "../Footer";

function GetLogin(props) {
  let auth = useAuth();
  let navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const {
    value: userName,
    // setValue: setUserName,
    isValid: userNameValid,
    hasError: userNameError,
    valueChangedHandler: userNameChangedHandler,
    inputBlurHandler: userNameBlur,
  } = useInput((value) => value.trim() !== ""); //check if is empty
  const {
    value: passValue,
    isValid: passwordValid,
    hasError: passwordError,
    valueChangedHandler: passwordChangedHandler,
    inputBlurHandler: passwordBlur,
  } = useInput((value) => value.trim() !== "");

  //so if the default username and password exist in server, navigate to the next page.

  let loginIsValid = false;
  if (userNameValid && passwordValid) {
    loginIsValid = true;
  }

  const togglePassVisibility = (e) => {
    // e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    auth.signIn(userName, passValue).then((res) => {
      navigate("/admin/meetings");
    });
  };

  return (
    <div className="md:grid place-items-center md:h-screen sm:rounded-lg body-inscription">
      <div className="phone-lout-gc">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            className="mb-5 ml-32"
            style={{ margin: "auto", height: 200 }}
          />
        </Link>
        <div className="py-10 px-10 bg-white card-cedula">
          <div>
            <form onSubmit={submitHandler}>
              <div className="max-w-xl text-sm text-black-500">
                <label htmlFor="user" className="">
                  Usuario
                </label>
                <input
                  name="user"
                  id="user"
                  className="mt-2 px-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full block sm:text-sm border-gray-300 rounded-md input-cedula "
                  value={userName}
                  onChange={userNameChangedHandler}
                  onBlur={userNameBlur}
                />
                {userNameError && (
                  <p className="error-text">Ingrese su nombre de usuario.</p>
                )}
              </div>

              <br />
              <div className="max-w-xl text-sm text-black-500">
                <label htmlFor="password" className="">
                  Contraseña
                </label>
                <div>
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password"
                    className="mt-2 px-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full block sm:text-sm border-gray-300 rounded-md input-cedula "
                    value={passValue}
                    onChange={passwordChangedHandler}
                    onBlur={passwordBlur}
                  />
                  <button type="button" onClick={togglePassVisibility}>
                    {passwordShown ? <EyeIcon /> : <EyeIconOff />}
                  </button>
                  {passwordError && (
                    <p className="error-text-lg">Ingrese su contraseña.</p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
              >
                Ingresar
              </button>
            </form>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GetLogin;
