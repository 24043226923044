import { BounceLoader } from 'react-spinners';

const Loader = ({ color, size }) => {
  return (
    <div className="flex justify-center items-center m-10">
      <BounceLoader color={color} size={size} />
    </div>
  );
};

Loader.defaultProps = {
  color: '#8527aa',
  size: 70,
};

export default Loader;
