import { useState } from 'react';

//having a custome hook
const useInput = (validateValue, initial = '') => {
  //manage value of the given input
  const [enteredValue, setEnteredValue] = useState(initial);
  //manage touch state of the given input
  const [isTouched, setIsTouched] = useState(false);
  //manage toggle input
  //   const [isToggle, setIsToggle] = useState(false);

  //manage validation of the given input
  const valueIsValid = validateValue(enteredValue); //check if value is valid with inline functions on GetCedula.jsx.
  const hasError = !valueIsValid && isTouched; //check error according to the input.

  const valueChangedHandler = (e) => {
    setEnteredValue(e.target.value); //store value name on the browser
  };
  const inputBlurHandler = () => {
    setIsTouched(true);
  };
  const resetForm = () => {
    setEnteredValue('');
    setIsTouched(false);
  };

  // purpose to write only numbers.
  const onlyNum = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setEnteredValue(e.target.value);
    }
  };

  return {
    value: enteredValue,
    setValue: setEnteredValue,
    isValid: valueIsValid,
    hasError: hasError,
    valueChangedHandler,
    inputBlurHandler,
    resetForm,
    onlyNum,
  };
};

export default useInput;
