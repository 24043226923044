import { CheckIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid";
import CircleImage from "../images/Circle.png";
import CircleImageEmpty from "../images/Circle-empty.png";
import React from "react";

const circleIcon = () => {
  /*return <img src={CircleImage} />;*/
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        border: "2px solid #be1622",
        backgroundColor: "#FFF",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          borderRadius: "50%",
          backgroundColor: "#be1622",
          width: 10,
          height: 10,
        }}
      ></div>
    </div>
  );
};
const circleIconEmtpy = () => {
  /*return <img src={CircleImageEmpty} />;*/
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        border: "2px solid #d0d5da",
        backgroundColor: "#FFF",
        width: "100%",
        height: "100%",
      }}
    ></div>
  );
};

const timeline = [
  {
    id: 1,
    content: "Registrar Nº cédula",
    icon: CheckIcon,
    iconBackground: "bg-yellow-500",
  },
  {
    id: 2,
    content: "Datos personales",
    icon: circleIcon,
    iconBackground: "bg-blue-500",
  },
  {
    id: 3,
    content: "Datos de la misión",
    icon: circleIconEmtpy,
    iconBackground: "bg-green-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TrackInscription(props) {
  return (
    <div className="flow-root track-inscription">
      <ul role="list" className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                    )}
                  >
                    <event.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-black-500">{event.content} </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

//Mision part tracking

const timeline2 = [
  {
    id: 1,
    content: "Registrar Nº cédula",
    icon: CheckIcon,
    iconBackground: "bg-yellow-500",
  },
  {
    id: 2,
    content: "Datos personales",
    icon: CheckIcon,
    iconBackground: "bg-yellow-500",
  },
  {
    id: 3,
    content: "Datos de la misión",
    icon: circleIcon,
    iconBackground: "bg-green-500",
  },
];

function classNames2(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function TrackInscription2(props) {
  return (
    <div className="flow-root track-inscription">
      <ul role="list" className="-mb-8">
        {timeline2.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline2.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames2(
                      event.iconBackground,
                      "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                    )}
                  >
                    <event.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-black-500">{event.content} </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
