import logo from "../images/logo-muc.svg";
import { useNavigate, Link } from "react-router-dom";
import useInput from "../hooks/use-input";
import { useEffect, useState } from "react";
import api from "../services/api";
import { BounceLoader } from "react-spinners";
import Loader from "./Loader";
import Footer from "./Footer";

export default function GetCedula() {
  const [endInscription, setEndInscription] = useState(false);
  const [startInscription, setStartInscription] = useState("");
  const {
    value: enteredCedula,
    setValue: setEnteredCedula,
    isValid: enteredCedulaIsValid,
    hasError: cedulaInputHasError,
    valueChangedHandler: cedulaChangedHandler,
    inputBlurHandler: cedulaBlurHandler,
    onChange: onlyNum,
    resetForm: resetCedulaInput,
  } = useInput((value) => value.trim() !== ""); //check if is empty

  //checking if the input is valid or not
  let inputIsValid = false;
  if (enteredCedulaIsValid) {
    inputIsValid = true;
  }

  const [mission, setMission] = useState(null);

  useEffect(() => {
    api.missions.getOne("active").then((m) => {
      setMission(m);
      const today = new Date();
      const end = new Date(m.entriesEnd);
      const fechaInicioUtc = new Date(m.entriesStart);

      const dia = fechaInicioUtc.getDate();
      const mes = fechaInicioUtc.getMonth() + 1; // Los meses van de 0 a 11, por lo que se suma 1
      const año = fechaInicioUtc.getFullYear();
      const hora = fechaInicioUtc.getHours();
      const minutos =
        fechaInicioUtc.getMinutes() === 0
          ? fechaInicioUtc.getMinutes() + "0"
          : fechaInicioUtc.getMinutes();

      const fechaFormateada = `${dia}/${mes}/${año} ${hora}:${minutos}`;
      setStartInscription(fechaFormateada);

      //console.log("hoy: ", today, " final: ", end);
      if (today > end) {
        setEndInscription(true);
        //console.log("incripciones cerradas");
      } else {
        setEndInscription(false);
      }
    });
  }, []);

  const [isRegistered, setIsRegistered] = useState(false);

  const handleSubmission = (e) => {
    e.preventDefault();
    api.entries
      .check(enteredCedula)
      .then((data) => {
        if (data.entryDate) {
          setIsRegistered(true);
        }
      })
      .catch((e) => {
        navigate(`/personal-data?govId=${enteredCedula}`);
      });
  };

  let navigate = useNavigate();

  const onCedulaChange = (e) => {
    if (e.target.value.length > 0)
      setEnteredCedula(e.target.value.match(/[A-Za-z0-9]+/g).join(""));
    else setEnteredCedula(e.target.value);
  };

  return (
    <div className="md:grid place-items-center md:h-screen sm:rounded-lg body-inscription">
      <Link to="/">
        <img src={logo} alt="logo" className="logo-in-cedula" />
      </Link>
      <div className="px-4 py-5 sm:p-6 phone-lout-gc">
        <h1 className="font-extrabold text-gray-700 title-cedula">
          Inscripciones {mission?.name}
        </h1>
        <div className="py-10 px-10 bg-white card-cedula">
          <div>
            <form onSubmit={handleSubmission}>
              <div className="max-w-xl text-sm text-black-500">
                <label htmlFor="cedula" className="">
                  Ingresá tu número de cédula
                </label>
                {endInscription ? (
                  <p className="mt-2 text-lg">
                    <strong>El periodo de inscripciones ha finalizado</strong>
                  </p>
                ) : (
                  ""
                )}
                <input
                  type="text"
                  name="cedula"
                  id="cedula"
                  className="mt-2 px-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full block sm:text-sm border-gray-300 rounded-md input-cedula "
                  placeholder="1234567"
                  value={enteredCedula}
                  onChange={onCedulaChange}
                  onBlur={cedulaBlurHandler}
                  disabled={endInscription ? true : false}
                />
                {cedulaInputHasError && (
                  <p className="error-text">Debés ingresar tu cédula.</p>
                )}
                {mission !== null &&
                  new Date(mission.entriesStart) > new Date() && (
                    <p className="error-text">
                      Puedes inscribirte a partir del {startInscription}
                    </p>
                  )}
                {isRegistered && (
                  <p className="error-text">
                    Ya te inscribiste. Podés verificar tus datos con el enlace
                    de abajo.
                  </p>
                )}
              </div>

              <br />
              <button
                type="submit"
                className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                disabled={
                  enteredCedula.length < 6 ||
                  new Date(mission.entriesStart) > new Date()
                }
              >
                Siguiente
              </button>
            </form>
            <br />
            <p
              className="text-gray-700"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              ¿Ya te inscribiste?
              <Link to="/verify-entry" className="md:ml-1">
                <strong className="ver-inscripcion">
                  {" "}
                  Verificar Inscripción
                </strong>
              </Link>
            </p>
          </div>
        </div>
        <div className="mt-10 text-center text-sm text-slate-500 hover:underline b-c-se-lout">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.muc.org.py/bases-y-condiciones/"
          >
            Bases y condiciones
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
