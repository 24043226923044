import React, { useState, useEffect } from 'react';
import SideBar from '../SideBar';
import { useQuery } from '../../../hooks';
import navigation from '../../../constants/sideCategories';
import ModalView from './ModalView';
import ModalTransfer from './ModalTransfer';
import SearchInput from './SearchInput';
import api from '../../../services/api';
import useInput from '../../../hooks/use-input';
import { debounce } from 'lodash';

function MissionariesList() {
  const [modalView, setModalView] = useState(false);
  const [modalTransfer, setModalTransfer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [towns, setTowns] = useState([]);
  const [transfer, setTransfer] = useState(true);
  const [missionary, setMissionary] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [refresh, setRefresh] = useState(false);
  const [totalData, setTotalData] = useState(null);
  let query = useQuery();

  const {
    value: enteredSelectedTown,
    setValue: setEnteredSelectedTown,
    isValid: enteredSelectedTownIsValid,
    hasError: selectedTownHasError,
    valueChangedHandler: selectedTownChangedHandler,
    inputBlurHandler: selectedTownBlurHandler,
    // onChange: onlyNum,
    resetForm: resetSelectedTown,
  } = useInput((value) => value);

  const {
    value: enteredAsignTown,
    setValue: setEnteredAsignTown,
    isValid: enteredAsignTownIsValid,
    hasError: asignTownHasError,
    valueChangedHandler: asignTownChangedHandler,
    inputBlurHandler: asignTownBlurHandler,
    // onChange: onlyNum,
    resetForm: resetAsignTown,
  } = useInput((value) => value);

  useEffect(() => {
    async function fetchData() {
      const _mission = await api.missions.getOne('active');
      api.entryList
        .getMulti('title', null, limit, offset, {
          mission: _mission.id,
          towns: enteredSelectedTown,
          selected_town: enteredAsignTown,
        })
        .then((data) => {
          setSearchResults(data.results);
          setTotalData(data.count);
        });
      api.towns
        .getMulti(null, null, 100, 0, { missions: _mission.id })
        .then(setTowns);
    }
    fetchData();
    setRefresh(false);
    console.log(enteredSelectedTown, enteredAsignTown);
  }, [
    searchValue.length == 0,
    enteredSelectedTown,
    enteredAsignTown,
    offset,
    refresh == true,
  ]);

  /*const search = () => {
    const searchResults = missionaries.filter(
      (item) =>
        item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.issuedId.includes(searchValue) ||
        item.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
        (
          item.firstName.toLowerCase() +
          " " +
          item.lastName.toLowerCase()
        ).includes(searchValue.toLowerCase()) ||
        (
          item.lastName.toLowerCase() +
          " " +
          item.firstName.toLowerCase()
        ).includes(searchValue.toLowerCase())
    );
    setMissionaries(searchResults);
  };*/

  const search = debounce((value) => {
    // Llama a la API o base de datos para buscar coincidencias
    async function fetchData() {
      const _mission = await api.missions.getOne('active');
      api.entryList
        .getMulti('title', value, limit, offset, {
          mission: _mission.id,
          towns: enteredSelectedTown,
          selectedTown: enteredAsignTown,
        })
        .then((data) => {
          setSearchResults(data.results);
        });
    }
    fetchData();
  }, 500);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    // Llama a la función de búsqueda debounced después de 500 ms
    search(searchValue);
  };

  function getAge(date) {
    let today = new Date();
    let birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <>
      <SideBar
        name={'Misioneros'}
        href={`/admin/missionaries-list`}
        current={[
          (navigation[0].current = false),
          (navigation[1].current = false),
          (navigation[2].current = false),
          (navigation[3].current = true),
        ]}
      />

      <div className="md:pl-72 flex items-center">
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleInputChange={handleInputChange}
        />

        <div className="w-1/3">
          <label
            htmlFor="pueblo-sel-input"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Pueblo seleccionado:
          </label>
          <div className="mt-2">
            <select
              id="pueblo-sel-input"
              name="pueblo-sel-input"
              autoComplete="off"
              className="border border-gray-300 text-gray-900 rounded-lg pl-10 p-2.5"
              value={enteredSelectedTown}
              onChange={selectedTownChangedHandler}
              onBlur={selectedTownBlurHandler}
            >
              <option value="">Todos</option>
              {towns.map((town) => (
                <option value={town.id} key={town.id}>
                  {town.name}
                </option>
              ))}
              <option value="null" key={0}>
                Ninguno
              </option>
            </select>
          </div>
        </div>
        <div className="w-1/3">
          <label
            htmlFor="pueblo-asig-input"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Pueblo asignado:
          </label>
          <div className="mt-2">
            <select
              id="pueblo-asig-input"
              name="pueblo-asig-input"
              autoComplete="off"
              className="border border-gray-300 text-gray-900 rounded-lg pl-10 p-2.5"
              value={enteredAsignTown}
              onChange={asignTownChangedHandler}
              onBlur={asignTownBlurHandler}
            >
              <option value="">Todos</option>
              {towns.map((town) => (
                <option value={town.id} key={town.id}>
                  {town.name}
                </option>
              ))}
              <option value="null" key={0}>
                Sin pueblo
              </option>
            </select>
          </div>
        </div>
      </div>

      <main className="md:pl-72 flex-1 mr-7">
        <table className="mt-8 min-w-full divide-y divide-gray-200 border">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Nombre
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Apellido
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Celular
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Pueblo&nbsp;1
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Pueblo&nbsp;2
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Pueblo asignado
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((m) => (
              <tr key={m.id} className="even:bg-gray-50 odd:bg-white">
                <td className="p-4">{m.missionary.firstName}</td>
                <td className="p-4">{m.missionary.lastName}</td>
                <td className="p-4">{m.missionary.cellphone}</td>
                <td className="p-4">{m.town1}</td>
                <td className="p-4">{m.town2}</td>
                <td className="p-4">{m.selectedTown}</td>
                <td className="p-4">
                  <a
                    href={'#'}
                    className={`ver-inscripcion py-2 px-4`}
                    onClick={() => {
                      setMissionary(m);
                      setModalView(true);
                    }}
                  >
                    Ver
                  </a>
                  {m.canTransfer && (
                    <a
                      href={'#'}
                      className={`ver-inscripcion py-2 px-4 ${
                        transfer ? 'inline' : 'hidden'
                      }`}
                      onClick={() => {
                        setMissionary(m);
                        setModalTransfer(true);
                      }}
                    >
                      Transferir
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {modalView ? (
          <ModalView
            modalView={modalView}
            setModalView={setModalView}
            setModalTransfer={setModalTransfer}
            missionary={missionary}
          />
        ) : null}
        {modalTransfer ? (
          <ModalTransfer
            modalTransfer={modalTransfer}
            setModalTransfer={setModalTransfer}
            missionary={missionary}
            setRefresh={setRefresh}
          />
        ) : null}
        {totalData > 100 ? (
          <nav
            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Mostrando <span className="font-medium">{offset + 1}</span> a{' '}
                <span className="font-medium">{offset + limit}</span> de{' '}
                <span className="font-medium">{totalData}</span> resultados
              </p>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end">
              <a
                onClick={() => {
                  if (offset) {
                    setOffset(offset - limit);
                  } else {
                    return;
                  }
                }}
                href="#"
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
              >
                Anterior
              </a>
              <a
                onClick={() => {
                  if (limit < totalData) {
                    setOffset(offset + limit);
                  } else {
                    return;
                  }
                }}
                href="#"
                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
              >
                Siguiente
              </a>
            </div>
          </nav>
        ) : null}
      </main>
    </>
  );
}

export default MissionariesList;
