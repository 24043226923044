import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useQuery } from "../../../hooks";
import { useAuth } from "../../../context/authContext";
import SideBar from "../../dashboard/SideBar";
import { formatDate } from "../../../utils";

function EncountersRegistPart3() {
  const [entry, setEntry] = useState(null);
  const [meetingAttendance, setMeetingAttendance] = useState(null);
  const [hasCheckIn, setHasCheckIn] = useState(false);
  const [hasCheckOut, setHasCheckout] = useState(false);
  const [error, setError] = useState("");
  const [currentMeeting, setCurrentMeeting] = useState({});

  let query = useQuery();
  let auth = useAuth();
  let navigate = useNavigate();

  // Obtener la fecha actual
  const currentDate = new Date();

  // Convertir la fecha de inicio a un objeto Date
  const meetingStartDate = new Date(currentMeeting?.start);

  // Convertir la fecha de terminacion a un objeto Date
  const meetingEndDate = new Date(currentMeeting?.end);

  // Verificar si la fecha actual es mayor o igual a la fecha de la reunión
  const showButtons = currentDate >= meetingStartDate;

  const showExitButton = currentDate >= meetingEndDate;

  // Calcular la fecha límite (fecha de inicio + tolerancia en minutos)
  const deadline = new Date(
    meetingStartDate.getTime() + currentMeeting?.tolerance * 60000
  );

  const showErrorMsg = currentDate > deadline;

  console.log(currentDate, meetingStartDate, deadline);

  useEffect(() => {
    api.meetings.getOne(query.get("meetingId")).then((res) => {
      setCurrentMeeting(res);
      console.log(res);
    });
  }, [query]);

  useEffect(() => {
    api.meetings.check(query.get("meetingId"), query.get("ci")).then((res) => {
      setEntry(res.entry);
      setHasCheckIn(res.hasCheckIn);
      setHasCheckout(res.hasCheckOut);
      // Si ya se ha realizado el check-in, entonces consultamos los datos de meetingAttendance
      if (res.hasCheckIn) {
        api.meetingAttendances
          .getOneByMissionaryId(res.entry.missionary.id)
          .then((attendance) => {
            setMeetingAttendance(attendance);
          })
          .catch((error) => {
            console.error(
              "Error al obtener los datos de meetingAttendance:",
              error
            );
          });
      }
    });
  }, [query]);

  const onCheck = (checkType) => {
    api.meetingAttendances
      .create({
        missionary: entry.missionary.id,
        meeting: query.get("meetingId"),
        type: checkType,
      })
      .then((res) => {
        const meetingId = query.get("meetingId");
        const meetingTitle = query.get("meetingTitle");
        const status = "success";
        navigate(
          `/admin/meetings/registration?meetingId=${meetingId}&meetingTitle=${meetingTitle}&status=${status}`
        );
      })
      .catch((e) => {
        setError(
          "No se pudo registrar la asistencia. Favor contactar con soporte técnico."
        );
      });
  };

  return (
    <>
      <SideBar />
      <div className="md:pl-44 md:grid place-items-center sm:rounded-lg body-inscription">
        <div className="px-4 py-5 sm:p-6 ">
          <h1 className="font-extrabold text-gray-700 title-cedula">
            Registro de Asistencia
            <br />
            Encuentro Previo: {query.get("meetingTitle")}
          </h1>
          <div className="flex justify-center">
            <div className="py-10 px-5 md:px-10 bg-white card-encounters card-cedula-ph">
              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Nombre</p>
                  <p>
                    {entry?.missionary.firstName} {entry?.missionary.lastName}
                  </p>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Cédula</p>
                  <p>{entry?.missionary.issuedId}</p>
                </div>
              </div>

              <br />

              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Fecha y hora de inscripción</p>
                  <p>
                    {entry &&
                      new Date(entry.entryDate).toLocaleDateString("es-py", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </p>
                </div>
                {/* <div className="col-span-3 sm:col-span-3">
                <p className="text-gray-500">Fecha y hora de inscripción</p>
                <p>{entry?.entryDate && parseDateStr(entry?.entryDate)}</p>
              </div> */}
              </div>

              <br />

              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Correo electrónico</p>
                  <p>{entry?.missionary.email}</p>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Teléfono</p>
                  <p>{entry?.missionary?.cellphone}</p>
                </div>
              </div>

              <br />

              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Lista de espera</p>
                  {entry?.isInWaitingList ? (
                    <p className="text-red-700">SI</p>
                  ) : (
                    <p className="text-green-700">NO</p>
                  )}
                </div>
                {/*<div className="col-span-3 sm:col-span-3">
                <p className="text-gray-500">Vacunación COVID-19</p>
                {entry?.isVaccinated ? (
                  <p className="text-green-700">SI</p>
                ) : (
                  <p className="text-red-700 font-semibold iph-se-lout">NO</p>
                )}
              </div>*/}
              </div>

              <br />
              {error && <small className="text-red-700">{error}</small>}
              <br />

              <div className="flex">
                <button
                  type="button"
                  disabled={hasCheckIn || !showButtons}
                  className="flex-1 px-10 font-medium text-white border border-transparent shadow-sm rounded-md btn-sig mr-10"
                  onClick={() => {
                    if (showErrorMsg) {
                      setError(
                        "Se ha superado el tiempo de tolerancia para registrar la entrada."
                      );
                    } else {
                      setError(""); // Limpiar el mensaje de error si no hay problema
                      onCheck("checkin");
                    }
                  }}
                >
                  Registrar entrada
                </button>

                <button
                  type="submit"
                  className="flex-1 px-10 font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                  disabled={hasCheckOut || !hasCheckIn || !showExitButton}
                  onClick={() => onCheck("checkout")}
                >
                  Registrar salida
                </button>
              </div>
              {meetingAttendance && (
                <>
                  <div className="md:grid gap-8 md:grid-cols-6 md:pb-5 mt-2 text-center">
                    <div className="col-span-3 sm:col-span-3">
                      <p className="text-gray-500">Registro de entrada</p>
                      <p>{formatDate(meetingAttendance[0]?.checkIn)}</p>
                    </div>
                    {hasCheckOut && (
                      <div className="col-span-3 sm:col-span-3">
                        <p className="text-gray-500">Registro de salida</p>
                        <p>{formatDate(meetingAttendance[0]?.checkOut)}</p>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 10,
                  gap: 50,
                }}
              >
                {!showButtons && (
                  <div className="text-red-700 text-center">
                    Actualmente no es posible realizar el check-in ya que el
                    encuentro aún no ha comenzado.
                  </div>
                )}
                {!showExitButton && (
                  <div className="text-red-700 text-center">
                    Actualmente no es posible realizar el check-out ya que el
                    encuentro aún no ha terminado.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EncountersRegistPart3;
