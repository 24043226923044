import { useState, useEffect } from "react";
import api from "../../../services/api";
import { useQuery } from "../../../hooks";
import SideBar from "../SideBar";
import navigation from "../../../constants/sideCategories";
import { peoplePayment } from "../../../constants/people";
import { formatDate, thousandSeparator } from "../../../utils";

export default function Cobros() {
  let query = useQuery();

  const [transactions, setTransactions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  useEffect(() => {
    api.transactions
      .getMulti(null, null, null, null, { date: selectedDate })
      .then((res) => {
        setTransactions(res);
      });
  }, [selectedDate]);

  const getTotal = () => {
    let total = 0;
    transactions.forEach((tx) => {
      total += tx.amountReceived;
    });
    return total;
  };
  return (
    <>
      <SideBar
        name={"Cobros"}
        href={`/admin/cobros`}
        current={[
          (navigation[0].current = false),
          (navigation[1].current = false),
          (navigation[2].current = true),
          (navigation[3].current = false),
        ]}
      />
      <main className="md:pl-72 flex-1">
        <div className="px-3 py-6">
          <div className="max-w-7xl mx-auto md:px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div className="-gray-200 rounded-lg h-96">
                {/* Replace with your content */}
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="born-input"
                    className="ver-inscripcion block text-lg text-black-900"
                  >
                    <strong>Selecciona una Fecha</strong>
                  </label>
                  <input
                    type="date"
                    name="date"
                    autoComplete="off"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
                <table className="mt-8 min-w-full divide-y divide-gray-200 border">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Misionero
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Monto Recibido
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Fecha recibida
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ¿Recibo enviado?
                      </th>
                    </tr>
                  </thead>
                  {transactions && (
                    <tbody className="bg-white divide-y divide-gray-200">
                      {transactions.map((tx, idx) => (
                        <tr
                          key={tx.id}
                          className={idx % 2 === 0 ? "bg-white" : "bg-gray-100"}
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {tx.missionary}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {thousandSeparator(tx.amountReceived)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatDate(tx.createdAt)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {tx.receiptSent ? "Si" : "No"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {transactions.length === 0 && (
                  <p className="mt-5 ml-5 error-text">
                    No hay cobros para la fecha seleccionada.
                  </p>
                )}
                <p className="mt-4 text-black-700">
                  <strong>
                    Total Recibido:
                    <span className="ml-2">
                      {thousandSeparator(getTotal())}
                    </span>
                  </strong>
                </p>
                {/* /End replace */}
              </div>
            </div>
            {/* /End replace */}
          </div>
        </div>
      </main>
    </>
  );
}
