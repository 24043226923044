//HOOKS AND SERVICES
import { useState, useEffect } from "react";
import api from "../../../services/api";
import SideBar from "../../dashboard/SideBar";
//REACT-ROUTER-DOM
import { Link, useNavigate } from "react-router-dom";
import navigation from "../../../constants/sideCategories";

//FIRST PART ENCOUNTER REGISTRATION
function EncountersRegist(props) {
  let navigate = useNavigate();

  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState("");

  const onSubmission = (e) => {
    e.preventDefault();
    const meetingData = meetings.find(
      (e) => e.id === parseInt(selectedMeeting)
    );
    navigate(
      `/admin/meetings/registration?meetingId=${selectedMeeting}&meetingTitle=${meetingData.title}`
    );
  };

  useEffect(() => {
    api.meetings.getMulti().then((data) => {
      setMeetings(data);
      setSelectedMeeting(data[0].id);
    });
  }, []);

  return (
    <>
      <SideBar
        name={"Registro de Asistencia"}
        href={"/admin/meetings"}
        current={[
          (navigation[0].current = true),
          (navigation[1].current = false),
          (navigation[2].current = false),
          (navigation[3].current = false),
        ]}
      />
      <div className="md:pl-64 sm:px-6 md:px-8">
        <div className="md:grid place-items-center md:mt-32 sm:rounded-lg body-inscription">
          <div className="phone-lout-gc">
            <div className="py-10 px-10 bg-white card-cedula">
              <div>
                <form onSubmit={onSubmission}>
                  <div className="max-w-xl">
                    <label htmlFor="select-date" className="text-black-500">
                      <h1>
                        <strong>Encuentro previo</strong>
                      </h1>
                    </label>
                    <select
                      id="select-date"
                      name="select-date"
                      autoComplete="off"
                      className="mt-2 block w-full py-3 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => setSelectedMeeting(e.target.value)}
                    >
                      {meetings.map((meeting) => (
                        <option value={meeting.id} key={meeting.id}>
                          {meeting.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  <br />
                  <button
                    type="submit"
                    className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                  >
                    Siguiente
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EncountersRegist;
